/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { MagnifyingGlassIcon, CameraIcon, MicrophoneIcon, SignalIcon, ChevronDownIcon, ArrowLeftStartOnRectangleIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid'
import DeviceList from "../DeviceList"
import Button from "../Button"
import { getUserDevicesInfo, playChatNotification, playMentionsNotification } from "../../utils/AudioVideoDevices";
import { deviceSelector, setAudioDevice, setAudioDevices, setVideoDevice, setVideoDevices } from '../../features/device/deviceSlice'
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import toast from "react-hot-toast";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { getAllSupervisorForManager, getUserDetails } from "../../services/userDetails";
import { getInitials } from "../../utils/initials";
import { personalizationSelector } from "../../features/personalization/personalization";
import { getPersonalizationSettings } from "../../services/personalization";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { Provider } from "react-redux";
import ActivityChannel from "../ActivityChannel";
import { createRoot } from "react-dom/client";
import { store } from "../../app/store";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { activityChannelSelector, removeDeletedFeed, setActivityPopup, setFeeds, setModalShowUrgentPost, setSelectedFeedTopic, setSelectedFeedTopicId, setUnreadPostsCount, updateFeeds } from "../../features/activityChannel/activityChannelSlice";
import { getRooms } from "../../services/room";
import { roomSelector } from "../../features/room/roomSlice";
import { NavControlProps } from "../../interfaces/componentsInterface/navControls";
import ChatList from "../ChatList";
import { chatSelector, clearUnseenId, setNewMessageToList, setPopupOpenedFrom } from "../../features/chat/chatSlice";
import { constantMethod } from "../../utils/constantMethod";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { Subscription } from "rxjs";
import SocketService from "../../services/socket";
import { role, socketConnectiontype, socketPathName } from "../../utils/constant";
import { getConnectionDetailsForChat } from '../../services/connectionDetails';
import { resetChatListing, resetOnlineUsers, resetTypingState, seenMessage, setMessageToList, setOnlineUsers, softDeleteMessage, softEditMessage, updateTypingStatus } from "../../features/chat/chatSlice";
import { IConnectionDetails } from "../../interfaces/componentsInterface/chats";
import { fetchChatListing } from "../../services/chat.service";
import { notifyMe } from "../../utils/desktopNotification";
import { getAllFeeds, getAllTopicsExtended, getConnectionDetailsForActivityChannel, getSubscribedTopics } from "../../services/activitychannel";
import { Role } from "../../interfaces/slicesInterface/activityChannel";
import { logOutActiveTime, logoutUser } from "../../services/login";
import { truncateMessage } from "../../utils/truncateMessage";



export default function NavControls(props: NavControlProps): ReturnType<React.FC> {


  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const devices = useAppSelector(deviceSelector)
  const user = useAppSelector(userDetailsSelector);
  // const personalization = useAppSelector(personalizationSelector);
  // const environments = useAppSelector(environmentSelector);
  // const spacesConfig = environments.environments && environments.environments.REACT_APP_SPACES_CONFIG ? environments.environments.REACT_APP_SPACES_CONFIG.data[0] : '';
  // const [imageError, setImageError] = useState(false);
  const [isACPopupOpened, setsIsACPopupOpened] = useState(false);
  const { activityPopup } = useAppSelector(activityChannelSelector);
  let ACPopup = useRef<Window | null>(null);
  let chatPopup = useRef<Window | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [, setSelectedOptions] = useState<string[]>([]);
  const [isChatPopupOpened, setsIsChatPopupOpened] = useState(false);
  const loggedInUser = useAppSelector(userDetailsSelector);
  const socketInstanceChat = useRef<any | null>(null);
  const messageSubscriberChatRef = useRef<Subscription | null>(null);
  const roomDetails = useAppSelector(roomSelector);
  let messageSubscriberACRef = useRef<Subscription | null>(null);
  let socketInstanceAC = useRef<any | null>(null);

  // const [active, setActive] = useState(true)
  // const [showOptions, setShowOptions] = useState(false);


  useEffect(() => {
    dispatch(getUserDetails({ data: "" }))
    dispatch(getAllSupervisorForManager())
    dispatch(getRooms());
  }, [dispatch])


  useEffect(() => {
    dispatch(getPersonalizationSettings())
  }, [dispatch])
  useEffect(() => {
    const getMediaDevicesInfo = async () => {
      try {
        try {
          const mediaDevicesInfo = await getUserDevicesInfo();
          const audioDevices = mediaDevicesInfo.filter(
            (mediaDeviceInfo) => mediaDeviceInfo.kind === "audioinput"
          );
          dispatch(setAudioDevices(audioDevices));
          if (audioDevices.length) {
            if (devices.activeAudioDevice) {
              const filteredItem = audioDevices.filter((item) => (item.deviceId === devices.activeAudioDevice));
              dispatch(setAudioDevice(filteredItem[0] && filteredItem[0].deviceId));
            } else {
              dispatch(setAudioDevice(audioDevices[0].deviceId));
            }

          }

          const videoDevices = mediaDevicesInfo.filter(
            (mediaDeviceInfo) => mediaDeviceInfo.kind === "videoinput"
          );
          dispatch(setVideoDevices(videoDevices));
          if (videoDevices.length) {
            if (devices.activeVideoDevice) {
              const filteredItem = videoDevices.filter((item) => (item.deviceId === devices.activeVideoDevice));
              dispatch(setVideoDevice(filteredItem[0] && filteredItem[0].deviceId));
            } else {
              dispatch(setVideoDevice(videoDevices[0].deviceId));
            }

          }
        } catch (err) {
          toast.error("Could not list media devices");
          console.error(err);
        }
      } catch (err) {
        toast.success("Please provide mic and video device access");
        console.error(err);
      }
    };
    getMediaDevicesInfo();
    navigator.mediaDevices.addEventListener("devicechange", (event) => {
      getMediaDevicesInfo();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.action === "closePopup") {
        if (ACPopup.current) {
          ACPopup.current.close();
          setsIsACPopupOpened(false);
          dispatch(setActivityPopup(false))
        }
      }

      if (event.data?.action === "closeChatPopup") {
        if (chatPopup.current) {
          chatPopup.current.close();
          setsIsChatPopupOpened(false);
          dispatch(setPopupOpenedFrom(""));
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);


  // const disconnectChatSocket = () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       if (socketInstanceChat.current) {
  //         socketInstanceChat.current.sendMessage("MANUAL_DISCONNECT_PEER", {});
  //       }

  //       if (socketInstanceChat.current?.messageSubscriber) {
  //         socketInstanceChat.current.messageSubscriber.unsubscribe();
  //         socketInstanceChat.current.messageSubscriber = null;
  //       }

  //       if (socketInstanceChat.current) {
  //         socketInstanceChat.current.closeSocketConnection(true);
  //         socketInstanceChat.current.reconnectFlushState();
  //         socketInstanceChat.current.socket = undefined;
  //         socketInstanceChat.current = null;
  //         dispatch(resetOnlineUsers());
  //         dispatch(resetChatListing());
  //         // closeChatsPopup();
  //       }

  //       resolve("Dashboard Chat Disconnected");
  //     } catch (err: any) {
  //       reject(`An error occurred during Desktop Chat disconnection: ${err.message}`);
  //     }
  //   });
  // };

  // const disconnectACSocket = () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       if (socketInstanceAC.current) {
  //         socketInstanceAC.current.sendMessage("MANUAL_DISCONNECT_PEER", {});
  //       }

  //       if (messageSubscriberACRef.current) {
  //         messageSubscriberACRef.current.unsubscribe();
  //         messageSubscriberACRef.current = null;
  //       }

  //       if (socketInstanceAC.current) {
  //         socketInstanceAC.current.closeSocketConnection(true);
  //         socketInstanceAC.current.reconnectFlushState(true);
  //         socketInstanceAC.current.socket = undefined;
  //         socketInstanceAC.current = null;
  //       }

  //       resolve("Dashboard AC Disconnected");
  //     } catch (err: any) {
  //       reject(`An error occurred during Desktop AC disconnection: ${err.message}`);
  //     }
  //   });
  // };



  useEffect(() => {
    const isOtherRoom = localStorage.getItem('other-room');
    if (user && store.getState().user.apiFulfilled && isOtherRoom === null) {

      if (user && user.role && !chatPopup.current && constantMethod.checkHigherRole(user.role)) {
        socketInstanceChat.current = new SocketService(
          socketConnectiontype.chat
        ).getInstance(socketConnectiontype.chat);

        fetchConnectionDetails();

        socketInstanceChat.current.messageSubscriber = socketInstanceChat.current
        .getMessages()
        .subscribe((message: string) => {
          processMessageForChat(JSON.parse(message));
        });
      }

      if (!ACPopup.current) {
        socketInstanceAC.current = new SocketService(socketConnectiontype.activityChannel).getInstance(
          socketConnectiontype.activityChannel
        );
        fetchConnectionDetailsForAC();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        socketInstanceAC.current.messageSubscriber = socketInstanceAC.current.getMessages().subscribe((message: string) => {
          processMessageFromACservice(JSON.parse(message));
        });
      }

    }

    return () => {

      // const disconnectSockets = async () => {

        // if (socketInstanceChat.current) {
        //   await socketInstanceChat.current.sendMessage(
        //     "MANUAL_DISCONNECT_PEER",
        //     {}
        //   );
        // }

        // if (socketInstanceChat.current?.messageSubscriber) {
        //   socketInstanceChat.current.messageSubscriber.unsubscribe();
        //   socketInstanceChat.current.messageSubscriber = null;
        // }
        // if (socketInstanceChat.current) {
        //   socketInstanceChat.current.closeSocketConnection(true);
        //   socketInstanceChat.current.reconnectFlushState();
        //   socketInstanceChat.current.socket = undefined;
        //   socketInstanceChat.current = null;
        //   dispatch(resetOnlineUsers());
        //   dispatch(resetChatListing());
        //   // closeChatsPopup();
        // }

        // if (socketInstanceAC.current) {
        //   socketInstanceAC.current.closeSocketConnection(true);
        //   socketInstanceAC.current.reconnectFlushState();
        //   socketInstanceAC.current.socket = undefined;
        //   // socketInstanceAC.current = null;
        // }

        // if (socketInstanceAC.current.messageSubscriber) {
        //   socketInstanceAC.current.messageSubscriber.unsubscribe();
        //   socketInstanceAC.current.messageSubscriber = null;
        // }

      // }

      // disconnectSockets();

    };

  }, [store.getState().user.apiFulfilled]);


  useEffect(() => {
    if (user.role === "Agent" && user.faceVerification.isFaceVerificationEnabled && !user.faceVerification.isRegistered) {
      navigate(routes.faceRegistration)
    }
  }, [navigate, user.faceVerification.isFaceVerificationEnabled, user.faceVerification.isRegistered, user.role])

  // Function to handle opening a popout window for the FEEDS component
  const openFeedsPopup = () => {
    let compiledTailwindCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/tailwind/tailwind.output.css`;
    let compiledEditorCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/react-draft-editor/react-draft-wysiwyg.css`
    if (!isACPopupOpened && !activityPopup) {
      setsIsACPopupOpened(true);
      dispatch(setActivityPopup(true))


      localStorage.setItem("ACPopOut", "true");
      // ACPopup.current = window.open("", "_blank", "width=500,height=650");
      // Calculate the top and left positions based on the current screen's available width and height
      const topPosition = window.screenY + (window.innerHeight * 0.15); // Adjust for multi-screen positioning
      const leftPosition = window.screenX + (window.innerWidth * 0.05); // Adjust for multi-screen positioning

      // Open popup with responsive width, height, and calculated top/left positions
      ACPopup.current = window.open("", "_blank", `width=500,height=650,top=${topPosition},left=${leftPosition}`);

      if (!ACPopup.current) {
        console.error("Failed to open feed popup window.");
        return;
      }

      ACPopup.current.document.open("text/html", "replace");

      ACPopup.current.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <title>ActivityChannel - Collaborationroom.ai</title>
            <link rel="stylesheet" href="${compiledTailwindCssUrl}">
            <link rel="stylesheet" href="${compiledEditorCssUrl}">
            <style>
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    scroll-behavior: smooth;
                }

                ::selection {
                    background-color: #4EA3DB;
                    color: #fff;
                }

                .scrollbar::-webkit-scrollbar {
                    width: 10px;
                }

                .scrollbar::-webkit-scrollbar-thumb {
                    background-color: #eaeaea;
                    border-radius: 10px;
                }

                .scrollbar::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }

                textarea::placeholder {
                    text-align: center;
                    padding: auto 0 !important;
                    display: table-cell;
                    vertical-align: middle !important;
                    margin-top: 10% !important;
                }

                p {
                    overflow-wrap: anywhere;
                    font-size: 0.75rem;
                }

                a {
                    color: blue;
                    text-decoration: inherit;
                    overflow-wrap: break-word;
                }

                textarea:focus {
                    outline: none;
                    max-height: 100%;
                    max-width: 100% !important;
                    overflow-y: scroll !important;
                    overflow-x: hidden;
                }

                .w-full__suggestions {
                    margin: 0 !important;
                    z-index: 1000;
                    margin-left: 2px;
                    top: 10% !important;
                    left: 10% !important;
                }

                .rdw-emoji-modal {
                  width: 7.5rem !important; /* Reduce the modal width */
                  height: 5rem !important; /* Reduce the modal height */
                }
                .rdw-emoji-modal .rdw-emoji-icon {
                  width: 17px; /* Adjust emoji size */
                  height: 17px;
                  font-size: 17px;
                }

                .w-full__suggestions__list {
                    z-index: 10;
                    background: white;
                    border: solid 1px #4EA3DB;
                    background: #4EA3DB;
                    color: #fff;
                }

                .w-full__highlighter {
                    height: auto !important;
                }

                textarea::-webkit-scrollbar {
                    width: 10px;
                }

                textarea::-webkit-scrollbar-thumb {
                    background-color: #eaeaea;
                    border-radius: 10px;
                }

                textarea::-webkit-scrollbar-thumb:hover {
                    background: #ccc;
                }

                :root {
                    --blue: #4EA3DB;
                }

                .activityscrollmenu {
                    height: 10rem !important;
                }

                h3 {
                    word-break: break-all !important;
                }

                #modal-headline {
                    color: #FF0000;
                }
                .text_editor {
                    width: 100%;
                    margin-top: 10px;
                    border: solid 1.5px #dadada;
                    border-radius: 5px;
                    padding: 5px;
                }

                .text_editor__controls {
                    margin-bottom: 10px;
                }

                .text_editor__controls button {
                    margin-right: 10px;
                }

               .text_editor__textarea.bold {
                    font-weight: bold;
                }

                .text_editor__textarea.italic {
                    font-style: italic;
                }

                .rdw-editor-main{
                    word-break: break-all;
                }

            </style>
            <script>
                window.addEventListener('storage', (event) => {
                    if (event.key === 'ACPopOut') {
                        const isACPopOut = JSON.parse(event.newValue); // Parse the new value from storage
                        if (!isACPopOut) {
                            window.close();
                        }
                    }
                });

                window.addEventListener('beforeunload', () => {
                    if (window.opener) {
                        window.opener.postMessage({ action: "closePopup" }, "*");
                    }
                });
            </script>
        </head>
        <body>
            <div id="root"></div>
        </body>
        </html>
    `);




      ACPopup.current.document.close();

      ACPopup.current.onload = () => {
        const rootElement = ACPopup.current?.document.getElementById("root");

        if (rootElement) {
          const root = createRoot(rootElement);
          root.render(
            <Provider store={store}>
              <ActivityChannel
                // active={active}
                // setActive={setActive}
                isACPopupOpened={true}
                fromACPopup={true}
              />
            </Provider>
          );
        } else {
          console.error("Root element not found in the chat popup window.");
        }
      };


      const closePopup = () => {
        if (ACPopup.current) {
          ACPopup.current.close();
          setsIsACPopupOpened(false);
          dispatch(setActivityPopup(false))
        }
      };

      // Add event listener for beforeunload to close the popup on page reload or navigation
      window.addEventListener("beforeunload", closePopup);

      // Ensure the event listener is removed when the component is unmounted or popup is closed
      ACPopup.current.onbeforeunload = () => {
        setsIsACPopupOpened(false);
        dispatch(setActivityPopup(false))
        window.removeEventListener("beforeunload", closePopup);
        // localStorage.setItem("ACPopOut", "false");
      };
    }
  };

  useEffect(() => {
    localStorage.removeItem("ACPopOut");
    localStorage.removeItem("participantsPopOut");
    localStorage.removeItem("alertsPopout");
    dispatch(setActivityPopup(false))
  }, [])

  // Function to handle opening a popout window for the Chats component
  const openChatsPopup = () => {
    let compiledTailwindCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/tailwind/tailwind.output.css`;

    if (!isChatPopupOpened) {
      setsIsChatPopupOpened(true);
      dispatch(setPopupOpenedFrom("dashboard"))

      localStorage.setItem("chatPopOut", "true");
      // chatPopup.current = window.open("", "_blank", "width=500,height=650");
      // Calculate the top and left positions based on the current screen's available width and height
      const topPosition = window.screenY + (window.innerHeight * 0.09); // 21% from the top, adjusted for secondary screens
      const leftPosition = window.screenX + (window.innerWidth * 0); // 0% from the left, adjusted for secondary screens

      // Open popup with responsive width, height, and calculated top/left positions
      chatPopup.current = window.open("", "_blank", `width=500,height=650,top=${topPosition},left=${leftPosition}`);

      if (!chatPopup.current) {
        console.error("Failed to open chat popup window.");
        return;
      }

      chatPopup.current.document.open("text/html", "replace");

      chatPopup.current.document.write(`
              <!DOCTYPE html>
              <html lang="en">
              <head>
                <title>Chats - Collaborationroom.ai</title>
                <link rel="stylesheet" href="${compiledTailwindCssUrl}">
                <style>
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          scroll-behavior: smooth;
          /* overflow: hidden; */
        }

        ::selection {
          background-color: #4EA3DB;
          color: #fff;
        }
        .scrollbar::-webkit-scrollbar {
            width: 10px;
          }

          .scrollbar::-webkit-scrollbar-thumb {
            background-color: #eaeaea;
            border-radius: 10px;
          }

          .scrollbar::-webkit-scrollbar-thumb:hover {
            background: #ccc;
          }

          textarea::placeholder {
            // text-align: center;
            padding: auto 0 !important;
            display: table-cell;
            vertical-align: middle !important;
            margin-top: 10% !important;
          }
            textarea{
  padding-left: 0.4rem;
}

          textarea:focus{

            outline: none;
            max-height: 100%;
            max-width: 100% !important;
            overflow-y: scroll !important;
            overflow-x: hidden;

          }
            .mention {
             color: blue;
          }
          .w-full__suggestions{
            margin: 0 !important;
            z-index: 1000;
            margin-left: 2px;
            top: 10% !important;
            left: 10% !important;
          }
          .w-full__suggestions__list{
            z-index: 10;
            background: white;
            border: solid 1px #4EA3DB;
            background: #4EA3DB;
            color: #fff;
          }


.w-full__highlighter{
    height: auto !important;
  }
          textarea::-webkit-scrollbar {
            width: 10px;
          }

          textarea::-webkit-scrollbar-thumb {
            background-color: #eaeaea;
            border-radius: 10px;
          }

          textarea::-webkit-scrollbar-thumb:hover {
            background: #ccc;
          }

        :root {
          --blue: #4EA3DB;
        }
      </style>
                       <script>
                    window.addEventListener('storage', (event) => {
                        if (event.key === 'chatPopOut') {
                            const ischatPopOut = JSON.parse(event.newValue); // Parse the new value from storage
                            if (!ischatPopOut) {
                                window.close();
                            }
                        }
                    });

                    window.addEventListener('beforeunload', () => {
                        localStorage.removeItem("chatPopOut");
                        window.close()
                        window.opener.postMessage('chatPopupClosed', '*');
                        if (window.opener) {
                           window.opener.postMessage({ action: "closeChatPopup" }, "*");
                         }
                    });
                </script>
              </head>
              <body>
                <div id="root"></div>
              </body>
              </html>
            `);

      chatPopup.current.document.close();
      // chatPopup.current.onresize = function() {
      //     chatPopup.current && chatPopup.current.resizeTo(chatPopup.current.screenX * .01, chatPopup.current.screenY * .01);
      //   };

      chatPopup.current.onload = () => {
        const rootElement = chatPopup.current?.document.getElementById("root");

        if (rootElement) {
          const root = createRoot(rootElement);
          root.render(
            <Provider store={store}>
              <ChatList
                isChatPopupOpened={true}
                fromPopup={true}
                popupWindow={chatPopup.current}
              />
            </Provider>
          );
        } else {
          console.error("Root element not found in the chat popup window.");
        }
      };

      const closePopupChat = () => {
        if (chatPopup.current) {
          // if (chatSocketConnection) {
          //   chatSocketConnection.closeSocketConnection();
          // }
          chatPopup.current.close();
          setsIsChatPopupOpened(false);
          dispatch(setPopupOpenedFrom(""))
        }
      };

      // Add event listener for beforeunload to close the popup on page reload or navigation
      window.addEventListener("beforeunload", closePopupChat);

      chatPopup.current.onbeforeunload = () => {
        // if (chatSocketConnection) {
        //   chatSocketConnection.closeSocketConnection();
        // }
        setsIsChatPopupOpened(false);
        dispatch(setPopupOpenedFrom(""))
        window.removeEventListener("beforeunload", closePopupChat);
      };

      window.addEventListener('message', (event) => {
        if (event.data === 'chatPopupClosed') {
          // Perform the desired action here
          console.log("Chat popup was closed");
          setsIsChatPopupOpened(false);
          dispatch(setPopupOpenedFrom(""))
        }
      });
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: string) => {
    toggleDropdown()
    console.log({ option })
    if (option === 'Feeds') {
      openFeedsPopup();
    }
    if (option === 'Chats') {
      openChatsPopup();
    }
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((o) => o !== option) : [...prev, option]
    );
  };

  // const isSelected = (option: string) => selectedOptions.includes(option);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchValue(e.target.value);
  };

  // Fetch connection details for chat
  const fetchConnectionDetails = async () => {

    let hostnameChat = process.env.REACT_APP_CHAT_WS_URL!;

    if (!hostnameChat) {
      if (window.location.origin.includes("localhost")) {
        hostnameChat = "http://localhost:8084";
      } else {
        hostnameChat = window.location.origin; // Default to the current origin
      }
    }
    // if (process.env.REACT_APP_SOCKET_SERVER_URL)
    //   hostnameChat = process.env.REACT_APP_SOCKET_SERVER_URL;

    const connectionDetailsChat: IConnectionDetails =
      await getConnectionDetailsForChat(
        "",
        "",
        "dashboard"
      );
    socketInstanceChat.current.connectToSocket(
      hostnameChat,
      connectionDetailsChat.path,
      connectionDetailsChat.payload,
      connectionDetailsChat.iv,
      connectionDetailsChat.digest,
      roomDetails.viewMode,
      `${socketPathName.chat}`
    );
  };

  function checkRoomExist(message:any) {
    const messageRoomId = message.room.roomId;
    console.log({ messageRoomId,room:store.getState().room})
    const roomExists = store.getState().room.rooms.some(room => room._id === messageRoomId);
    return roomExists;
  }

  const getOnlineChatUsers = async () => {
    return await socketInstanceChat.current.sendMessage(
      "GET_ONLINE_CHAT_USERS",
      {}
    );
  };

  // Process incoming messages for chat
  const processMessageForChat = async (message: any) => {
    switch (message.type) {
      case "SELF_CONNECTION_ACKNOWLEDGE":
        console.log(message.selfSocketId + ": " + message.message);
        break;
      case "CHAT_SOCKET_CONNECTED":
        getOnlineChatUsers().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
          // Necessary. Because we want to assign socketId to Past user that just came online
          dispatch(fetchChatListing({
            loggedInUserId: loggedInUser.userId,
            clientId: loggedInUser.custId,
            roomId: roomDetails.currentRoomId,
          }));
        });
        break;
      case "CHAT_SOCKET_DISCONNECTED":
        console.log("Chat socket Disconnected: ", message);
        break;
      case "CHAT_LEAVE_NOTIFY":
        console.log(message);
        getOnlineChatUsers().then((response: any) => {
          dispatch(setOnlineUsers(response.users));
        });
        break;
      case "NEW_CHAT_MESSAGE":


        if (message) {
          let shouldNotifyAudio = false;
          let shouldNotifyToast = false;
          let shouldNotifyDesktop = false;
          const fullName = `@ ${loggedInUser.firstName} ${loggedInUser.lastName}‎`.toLowerCase();
          const messageContent = message.message.toLowerCase();
            if (messageContent.includes(fullName) && store.getState().personalization.personalization.settings?.mentions) {
              shouldNotifyAudio = true; // Flag to play audio notification
              shouldNotifyToast = true; // Flag to show toast notification
              shouldNotifyDesktop = true; // Flag to show desktop notification
            }

          // Play audio notification if needed
          if (shouldNotifyAudio && store.getState().personalization.personalization.settings?.mentions?.audio) {
            playMentionsNotification();
            shouldNotifyAudio = false; // Reset the flag
          }

          // Show toast notification if needed
          if (shouldNotifyToast && store.getState().personalization.personalization.settings?.mentions?.toast) {
            // const toastId = message.chatType === 'PRIVATE' ? "3" : "4";
            const toastMessage = `${message.sender.senderUsername} mentioned you in ${message.chatType === 'PRIVATE' ? 'Private Chat' : 'Room'}`;
            toast.success(toastMessage);
            shouldNotifyToast = false; // Reset the flag
          }

          // Show desktop notification if needed
          if (shouldNotifyDesktop && store.getState().personalization.personalization.settings?.mentions?.desktop) {
            const notificationMessage = `${message.sender.senderUsername} mentioned you in ${message.chatType === 'PRIVATE' ? 'Private Chat' : 'Room'}`;
            notifyMe(notificationMessage);
            shouldNotifyDesktop = false; // Reset the flag
          }
        }



        if (message && message?.sender.senderUserId !== loggedInUser.userId && store.getState().personalization.personalization?.settings) {
          const isAgentOrAdmin = loggedInUser.role === role.AGENT || loggedInUser.role === role.ADMIN;
          if ((!isAgentOrAdmin && checkRoomExist(message)) || isAgentOrAdmin) {

          if (store.getState().personalization.personalization?.settings?.chat?.audio) {
            playChatNotification();
          }

          if (store.getState().personalization.personalization?.settings?.chat?.toast) {
            message.chatType === 'PRIVATE' ? toast.success(`New Message From ${message.sender?.senderUsername}`) : toast.success(`New Message in ${truncateMessage(message.room.roomName,20)} from ${message.sender?.senderUsername}`)
          }

          if (store.getState().personalization.personalization?.settings?.chat?.desktop) {
            message.chatType === 'PRIVATE' ? notifyMe(`New Message From ${message.sender.senderUsername}`) : notifyMe(`New Message in ${message.room.roomName} from ${message.sender.senderUsername}`)
          }

        }
        }

        console.log('JDS1515')

        dispatch(setNewMessageToList(message))
        if(message.chatType==="PRIVATE"){
          dispatch(clearUnseenId())
        }
        if(store.getState()?.chat?.selectedChatUser?.chatType===message.chatType){
        dispatch(setMessageToList(message));
        }
        dispatch(fetchChatListing({
          loggedInUserId: loggedInUser.userId,
          clientId: loggedInUser.custId,
          roomId: roomDetails.currentRoomId,
        }));
        break;
      case "EDIT_MESSAGE":
        //if (chatDetails.messageList.length) {
        dispatch(
          softEditMessage({
            _id: message._id,
            message: message.editedMessage,
          })
        );
        //}
        break;
      case "DELETE_MESSAGE":
        dispatch(softDeleteMessage(message));
        dispatch(fetchChatListing({
          loggedInUserId: loggedInUser.userId,
          clientId: loggedInUser.custId,
          roomId: roomDetails.currentRoomId,
        }));
        break;
      case "TYPING":
        let typingData = {
          active: true,
          typerName: message.sender.peerName,
          typerUserId: message.sender.userId,
          typerSocketId: message.sender.senderSocketId,
          chatType: message.chatType,
        };
        dispatch(updateTypingStatus(typingData));
        break;
      case "STOP_TYPING":
        dispatch(resetTypingState());
        break;
      case "UPDATE_SEEN_STATUS":
        // let dataToSend = {
        //   senderSocketId: "",
        //   senderUserId: message.senderUserId,
        //   receiverUserId: message.receiverUserId,
        //   receiverSocketId: chatDetails.selectedChatUser.peerSessionId,
        // };
        // socketInstanceChat.current.sendMessage(
        //   "UPDATE_SEEN_STATUS_DB",
        //   dataToSend
        // );
        let dataToSend = {
          senderSocketId: "",
          senderUserId: message.receiverUserId,
          receiverUserId: message.senderUserId,
          receiverSocketId: store.getState().chat.selectedChatUser.peerSessionId,
        };
        socketInstanceChat.current.sendMessage(
          "UPDATE_SEEN_STATUS_DB",
          dataToSend
        );
        let partnerUser = {
          userId: message.senderUserId,
        };
        dispatch(seenMessage({ partnerUser }));
        break;
      default:
        break;
    }
  };

  // Activity Channel connection and process messages starts from here

  // Check if user has SPECIFIC permission for an TOPIC (permissionToCheck can be 'read', 'write' or 'none')
  const hasPermission = (
    topicId: string,
    currentRole: string,
    permissionToCheck: string
  ): boolean => {
    // Find the topic with the given ID
    const topic = store
      .getState()
      .activityChannel.subscribedTopics.find((topic: any) => topic._id === topicId);
    console.log("TOPIC", topic);
    // If found, check if the currentRole has SPECIFIC permission
    if (topic) {
      const rolePermissions = topic.permissions[currentRole as Role];
      console.log({ rolePermissions });
      console.log(rolePermissions.includes(permissionToCheck.toLowerCase()));
      return rolePermissions.includes(permissionToCheck.toLowerCase());
    }
    //  Return false if topic not found or read SPECIFIC not present
    return false;
  };

  const showNewPostNotification = (username: string) => {
    // Play audio notification if needed
    if (store.getState().personalization?.personalization?.settings?.activity?.audio) {
      playChatNotification();
    }

    // Show toast notification if needed
    if (store.getState().personalization?.personalization?.settings?.activity?.toast) {
      // const toastId: string = "5";
      const toastMessage = `New Post in Activity Channel from ${username}`;
      toast.dismiss();
      toast.success(`${toastMessage}`);
    }

    // Show desktop notification if needed
    if (store.getState().personalization?.personalization?.settings?.activity?.desktop) {
      const notificationMessage = `New Post in Activity Channel from ${username}`;
      notifyMe(notificationMessage);
    }
  };

  const getFeeds = async (topicIds: string[], pageNo: Number, fetchMyPostsOnly: boolean) => {
    if (topicIds.length > 0) {
      dispatch(getAllFeeds({ topicIds, pageNo, myPosts: fetchMyPostsOnly }));
    }
  };

  const processMessageFromACservice = async (message: any) => {
    console.log({ message });
    switch (message.type) {
      case "CHANNEL_SOCKET_CONNECTED":
        console.log("Successfully connected to socket through redis");
        break;

      case "CHANNEL_SOCKET_DISCONNECTED":
        console.log("DISCONNECTED FROM SOCKET SERVER");
        break;

      case "NEW_POST_ACTIVITY_CHANNEL":
        const feed = message.feed;

        if (
          store.getState().activityChannel.selectedFeedTopic === "topics" &&
          store.getState().activityChannel.subscribedTopicIds.includes(feed.topicId) &&
          hasPermission(feed.topicId, loggedInUser.role, "read")
        ) {
          dispatch(
            setUnreadPostsCount(store.getState().activityChannel.unreadPostsCount + 1)
          );
        }
        if (
          (store.getState().activityChannel.selectedFeedTopic === "allPosts" &&
            store
              .getState()
              .activityChannel.subscribedTopicIds.includes(feed.topicId)) ||
          store.getState().activityChannel.selectedFeedTopicId === feed.topicId
        ) {
          // getFeeds(store.getState().activityChannel.subscribedTopicIds, 1, false);
          dispatch(setFeeds(feed));
          if (feed.userId !== loggedInUser.userId) {
            // Don't show notifications to self
            showNewPostNotification(`${feed.username ? feed.username : `${feed.firstName + " " + feed.lastName}`}`);
          }
        }

        if (store.getState().activityChannel.selectedFeedTopic === "myPosts" && loggedInUser.userId === feed.userId) {
          dispatch(setFeeds(feed));
          if (feed.userId !== loggedInUser.userId) {
            // Don't show notifications to self
            showNewPostNotification(`${feed.username ? feed.username : `${feed.firstName + " " + feed.lastName}`}`);
          }
        }
        if (
          feed.type === "urgent" &&
          feed.userId !== loggedInUser.userId &&
          store.getState().activityChannel.subscribedTopicIds.includes(feed.topicId) &&
          hasPermission(feed.topicId, loggedInUser.role, "read")
        ) {
          dispatch(setModalShowUrgentPost(true));
        }
        break;
      case "UPDATE_FROM_ADMIN":
        if (message.msg === "FEED_DELETED") {
          dispatch(removeDeletedFeed(message.id));
        } else {
          dispatch(getAllTopicsExtended());
          dispatch(getSubscribedTopics());
          // setTopicSelected(null);

          if (message.id === store.getState().activityChannel.selectedFeedTopicId) {
            // setSelectedFeedTopic("allPosts");
            // setSelectedFeedTopicId("");
            dispatch(setSelectedFeedTopic("allPosts"));
            dispatch(setSelectedFeedTopicId(""));
          } else {
            if (
              store.getState().activityChannel.selectedFeedTopic === "allPosts" ||
              store.getState().activityChannel.selectedFeedTopic === "myPosts"
            ) {
              if (store.getState().activityChannel.selectedFeedTopic === "myPosts") {
                // getFeeds(
                //   store.getState().activityChannel.subscribedTopicIds,
                //   store.getState().activityChannel.pageNo,
                //   true
                // );
                dispatch(updateFeeds(message?.updatedFeed))
              } else {
                // getFeeds(
                //   store.getState().activityChannel.subscribedTopicIds,
                //   store.getState().activityChannel.pageNo,
                //   false
                // );
                dispatch(updateFeeds(message?.updatedFeed))

              }
            } else {
              // getFeeds([store.getState().activityChannel.selectedFeedTopicId], store.getState().activityChannel.pageNo, false);
              dispatch(updateFeeds(message?.updatedFeed))
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const fetchConnectionDetailsForAC = async () => {
    const connectionDetails = await getConnectionDetailsForActivityChannel(loggedInUser.username);


    let hostnameAC = process.env.REACT_APP_AC_WS_URL!;

    if (!hostnameAC) {
      if (window.location.origin.includes("localhost")) {
        hostnameAC = "http://localhost:8085";
      } else {
        hostnameAC = window.location.origin; // Default to the current origin
      }
    }

    console.log(
      "Connection details in dashboard",
      hostnameAC,
      connectionDetails.path,
      connectionDetails.payload,
      connectionDetails.iv,
      connectionDetails.digest
    );

    socketInstanceAC.current.connectToSocket(
      hostnameAC,
      connectionDetails.path,
      connectionDetails.payload,
      connectionDetails.iv,
      connectionDetails.digest,
      "",
      socketPathName.activityChannel
    );
  };

  const handleLogout = () => {
    dispatch(logOutActiveTime())
    dispatch(logoutUser());
    dispatch({ type: 'auth/logout' })
    localStorage.removeItem("ACPopOut");
    localStorage.removeItem("chatPopOut");
    localStorage.removeItem("participantsPopOut");
    localStorage.removeItem("alertsPopout");
    dispatch(setPopupOpenedFrom(""))
    localStorage.removeItem('isGeoLocationExported');
    localStorage.removeItem('isSamlLogin');
  }




  return (
    <>

      <div className=" container flex justify-between items-center w-full min-w-full gap-1">

        <div className="relative rounded-md shadow-sm  w-[20%] flex justify-center items-center">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search_text"
            id="search"
            className="block w-full rounded-md  py-1.5 pl-10 text-gray-900  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 focus:outline-none sm:text-sm sm:leading-6"
            placeholder="Search Rooms"
            value={props.searchValue}
            onChange={handleSearchChange}
          />
        </div>
        <DeviceList list={devices.videoDevices} activeDevice={devices.activeVideoDevice} setCurrentDevice={setVideoDevice} Icon={CameraIcon} />
        <DeviceList list={devices.audioDevices} activeDevice={devices.activeAudioDevice} setCurrentDevice={setAudioDevice} Icon={MicrophoneIcon} />
        {/* <Button Icon={SignalIcon}  type="primary" className="inline-flex items-center gap-x-1.5 rounded-md bg-blue px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue">
          Feeds
        </Button> */}
        <div className="relative inline-block text-left">
          {/* <Button
            onClick={toggleDropdown}
            type="primary"
            className="w-full inline-flex items-center gap-x-1.5 rounded-md bg-blue px-3 py-2 text-sm  text-white shadow-md hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
          >
            {constantMethod.checkHigherRole(user.role) ? <span>Chats & Feeds</span> : <span>Feeds</span>}
            <ChevronDownIcon className="w-6 h-6" />
          </Button> */}
          <button
            onClick={toggleDropdown}
            type="button"
            className="w-full  shadow-sm inline-flex items-center gap-x-1.5 justify-center rounded-md border-[1px] border-gray-50 bg-white  px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-offset-2"
          >
            <span className="mr-2 h-5 w-5">
              <SignalIcon />
            </span>
            {constantMethod.checkHigherRole(user.role) ? <span>Chats & Feeds</span> : <span>Feeds</span>}
            <ChevronDownIcon className="w-6 h-5" />
          </button>

          {isDropdownOpen && (
            <div className="absolute z-50 right-0 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="">
                <button
                  onClick={() => handleOptionClick('Feeds')}
                  className={`flex justify-center items-center align-middle gap-2 w-full px-4 py-2 text-left text-sm ${isACPopupOpened
                    ? 'bg-blue text-white font-semibold rounded-t-md'
                    : 'text-gray-800 hover:bg-gray-100'
                    }`}
                >
                  Feeds
                  <ArrowTopRightOnSquareIcon
                    className={`xl:h-4 xl:w-4 lg:h-3 lg:w-3 sm:h-3 sm-h-3 ${isACPopupOpened && 'text-gray-300'} text-base  hover:cursor-pointer transition-colors`}
                    title="Popout Feeds"
                  />
                </button>
                {constantMethod.checkHigherRole(user.role) && <button
                  onClick={() => handleOptionClick('Chats')}
                  className={`flex justify-center items-center align-middle gap-2 w-full px-4 py-2 text-left text-sm ${isChatPopupOpened
                    ? 'bg-blue text-white font-semibold rounded-b-md'
                    : 'text-gray-800 hover:bg-gray-100'
                    }`}
                >
                  Chats
                  <ArrowTopRightOnSquareIcon
                    className={`xl:h-4 xl:w-4 lg:h-3 lg:w-3 sm:h-3 sm-h-3 ${isChatPopupOpened && 'text-gray-300'} text-base  hover:cursor-pointer transition-colors`}
                    title="Popout Chats"
                  />
                </button>}
              </div>
            </div>
          )}
        </div>


        {/* <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-blue px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                >
                    <SignalIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    Activity Channel
                </button> */}


        {/* <div className="flex justify-center items-center gap-2 w-[20%]">
          {
            personalization.personalization && personalization.personalization.imageUrl && imageError ? <img
              className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
              src={personalization.personalization.imageUrl}
              alt=""

            /> : personalization.personalization && personalization.personalization.imageUrl && !imageError ? <img
              className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
              src={user.custId && user.userId && spacesConfig && generateThumbnailsUrl(personalization.personalization.imageUrl.split('/').pop(), 240, user.custId, user.userId, spacesConfig)}
              alt=""
              onError={() => setImageError(true)}
            /> :

              <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                <span className="text-sm font-medium leading-none text-white">{getInitials(`${user.firstName + ' ' + user.lastName}}`)}</span>
              </span>

          }


          <div className="flex flex-col justify-start items-start w-4/5" >
            <p className="w-full font-sans text-sm font-semibold capitalize truncate" title={`${user.firstName} ${user.lastName}(${user.custCode})`} >{`${user.firstName} ${user.lastName}(${user.custCode})`} </p>
            <p className="w-full font-sans text-sm text-gray-500 capitalize">{user.role}</p>
          </div>
        </div> */}
        <div onClick={handleLogout} className="mr-2">
          <ArrowRightStartOnRectangleIcon
            className="xl:w-10 w-8   text-white p-2  rounded-full   bg-red-600 cursor-pointer   transition-all duration-100 hover:opacity-80 "
            title="Logout"
          />
        </div>

      </div>

    </>
  )
}